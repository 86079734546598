import React, { Component } from "react";
// import PDF from "./Teszt.pdf";
import { Document, Page, pdfjs } from "react-pdf";


import "../App.css";

// const onDocumentLoadSuccess = ({ numPages }) => {
//   this.setState({
//     numPages: numPages,
//   });
//   console.log(numPages);
// };
// const styles = StyleSheet.create({
//   page: { backgroundColor: 'tomato' },
//   section: { color: 'white', textAlign: 'center', margin: 30 }
// });

class Pdf1 extends Component {
  state = {};
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({
      numPages: numPages,
    });
    console.log(numPages);
  };
  render() {
    console.log('PROPS', this.props);
    return (
      <div>
        <Document
          file={this.props.pdf}
          onContextMenu={(e) => e.preventDefault()}
          onLoadSuccess={this.onDocumentLoadSuccess}
          className="pdf-container"
        >
          {Array.apply(null, Array(this.state.numPages))
            .map((x, i) => i + 1)
            .map((page, ii) => (
              <Page
                key= {ii}
                style={{ backgroundColor: "tomato" }}
                pageNumber={page}
                width={800}
                // style={styles.page}
              />
            ))}
        </Document>
      </div>
    );
  }
}

export default Pdf1;
