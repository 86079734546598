import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useFolder } from "../../hooks/useFolder";
import AddFolderButton from "./AddFolderButton";
import AddFileButton from "./AddFileButton";
import Folder from "./Folder";
import File from "./File";
import Navbar from "./Navbar";
import FolderBreadcrumbs from "./FolderBreadcrumbs";
import { useParams, useLocation } from "react-router-dom";
import Pdf1 from "./Pdf1";
import { useAuth } from "../../contexts/AuthContext";

export default function Dashboard() {
  const selectFile = (a) => {
    console.log(a);
    console.log("File", file);
    setCount(count + 1);
    setFile(a);
  };
  const backToDirectory = (a) => {
    setFile(undefined);
  };
  const { folderId } = useParams();
  const { state = {file: undefined} } = useLocation();
  const { currentUser, logout } = useAuth();
  const { folder, childFolders, childFiles } = useFolder(
    folderId,
    state.folder
  );
  const [count, setCount] = useState(0);
  const [file, setFile] = useState(0);
  console.log();
  const isAdmin = currentUser.uid == "HVWRFINzzZPWdRWuxN7lTwIzpdZ2";
  return (
    <>
      <Navbar />
      <p>
        {/* {isAdmin ? "igen" : "nem"}
        {currentUser.uid}
        {file} */}
        {file && (
          <>
            <button onClick={() => backToDirectory(count)}>Vissza</button>
            <Pdf1 pdf={file} />
          </>
        )}
      </p>
      {!file && (
        <Container fluid>
          <div className="d-flex align-items-center">
            <FolderBreadcrumbs currentFolder={folder} />
            {isAdmin && !file && (
              <>
                <AddFileButton currentFolder={folder} />
                <AddFolderButton currentFolder={folder} />
              </>
            )}
          </div>
          {childFolders.length > 0 && (
            <div className="d-flex flex-wrap">
              {childFolders.map((childFolder) => (
                <div
                  key={childFolder.id}
                  style={{ maxWidth: "250px" }}
                  className="p-2"
                >
                  <Folder folder={childFolder} />
                </div>
              ))}
            </div>
          )}
          {childFolders.length > 0 && childFiles.length > 0 && <hr />}
          {childFiles.length > 0 && (
            <div className="d-flex flex-wrap">
              {childFiles.map((childFile) => (
                <div
                  key={childFile.id}
                  style={{ maxWidth: "250px" }}
                  className="p-2"
                >
                  <File file={childFile} selectFile={(a) => selectFile(a)} />
                </div>
              ))}
            </div>
          )}
        </Container>
      )}
    </>
  );
}
