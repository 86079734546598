import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useHistory } from "react-router-dom"

export default function File(params) {
  const {file, selectFile} =params;
  const history = useHistory()
  return (
    <div
      // href={file.url}
      onClick={() => {
        // history.push(`/pdf/${file.url}`);
        selectFile(`${file.url}`);
      }}
      target="_blank"
      className="btn btn-outline-dark text-truncate w-100"
    >
      <FontAwesomeIcon icon={faFile} className="mr-2" />
      {file.name}
    </div>
  );
}
